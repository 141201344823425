import { useAPI, useCacheWrapper, useSharedPromise } from '#imports'

export function useMetadataStore () {
  const api = useAPI()

  function getProductMetadata (code: string) {
    const key = ['product-metadata', code]
    const { getProductMetadata } = api.product()

    return useSharedPromise(key, () => useCacheWrapper(key, () => {
      return getProductMetadata(code)
    }))
  }

  function getProductReviewsMetadata (code: string) {
    const key = ['product-reviews-metadata', code]
    const { getReviewsMetadata } = api.reviews()

    return useSharedPromise(key, () => useCacheWrapper(key, () => {
      return getReviewsMetadata(code)
    }))
  }

  function getHomeMetadata () {
    const key = 'home-metadata'
    const { getHomeMetadata } = api.meta()

    return useSharedPromise(key, () => useCacheWrapper(key, () => {
      return getHomeMetadata()
    }))
  }

  return {
    getHomeMetadata,
    getProductMetadata,
    getProductReviewsMetadata
  }
}
